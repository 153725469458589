<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i
              class="fal fa-address-card"
              style="margin: 0 10px 0 0; font-size: 20px"
            ></i>
            <span @click="main()">{{ $t("scanTime.title") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "scan_time.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
